import React, { useContext } from "react"
import { RichText } from "prismic-reactjs"
import VisibilitySensor from "react-visibility-sensor"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"
import { linkResolver } from "../utils/linkResolver"

const AboutContentContainer = ({ content }) => {
  const [, setActiveItems] = useContext(ActiveScrollContext)
  function onChange(isVisible, id) {
    setActiveItems({ id, isVisible })
  }
  return (
    <>
      {content.map((item, i) => (
        <VisibilitySensor
          onChange={isVisible =>
            onChange(
              isVisible,
              item.primary.content_section_title[0].text.replace(" ", "_")
            )
          }
          key={item.primary.content_section_title[0].text.replace(" ", "_")}
          offset={{ top: 150, bottom: 250 }}
          partialVisibility="top"
        >
          <div>
            <div
              className="anchor"
              id={item.primary.content_section_title[0].text.replace(" ", "_")}
            />
            <h4>{RichText.asText(item.primary.content_section_title)}</h4>
            <div className="section-text">
              {RichText.render(item.primary.text, linkResolver)}
            </div>
          </div>
        </VisibilitySensor>
      ))}
    </>
  )
}

export default AboutContentContainer
