import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"
import AboutContentContainer from "../components/AboutContentContainer"
import TopSubNav from "../components/TopSubNav"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"
import { RichText } from "prismic-reactjs"

const HeroImage = styled("div")`
  height: 600px;
  width: 100%;
  background-color: #454545;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    max-width: 1920px;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }

  .hero-text {
    width: 100%;
    max-width: 1200px;
    flex: 0 0 auto;
    padding: 1rem;
    color: #ffffff;
    z-index: 1;

    h3 {
      font-size: calc(36px + (55 - 36) * ((100vw - 300px) / (1600 - 300)));
      width: 50%;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    p {
      font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
      width: 60%;

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    a {
      font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
    }
  }
`

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            hero_image
            body {
              ... on PRISMIC_AboutBodyText {
                primary {
                  content_section_title
                  text
                }
              }
            }
            hero_subtitle
            hero_title
            page_title
            top_sub_nav {
              nav_item
            }
          }
        }
      }
    }
  }
`

const links = [
  {
    key: "about",
    stub: "/about",
    displayName: "About",
  },
  {
    key: "team",
    stub: "/team",
    displayName: "Team",
  },
  {
    key: "capabilities",
    stub: "/capabilities",
    displayName: "Capabilities",
  },
  {
    key: "culture",
    stub: "/culture",
    displayName: "Culture",
  },
  {
    key: "careers",
    stub: "/careers",
    displayName: "Careers",
  },
]

const renderComponent = data => {
  const doc = data.prismic.allAbouts.edges.slice(0, 1).pop()
  if (!doc) return null
  const topScrollLinks = doc.node.body.map(b => ({
    id: b.primary.content_section_title[0].text.replace(" ", "_"),
    label: b.primary.content_section_title[0].text,
    isVisible: false,
  }))
  const formattedRegularLinks = formatRegularLinks(doc.node.top_sub_nav)

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        topScrollLinks={topScrollLinks}
        regularLinks={formattedRegularLinks}
      />
      <HeroImage imageUrl={doc.node.hero_image.url}>
        <div className="hero-text">
          <h3>{doc.node.hero_title[0].text}</h3>
          <p>{doc.node.hero_subtitle[0].text}</p>
        </div>
      </HeroImage>
      <ContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <AboutContentContainer
                content={doc.node.body}
                seoH1={doc.node.seoH1}
              ></AboutContentContainer>
            </div>
          </TwoColContainer>
        </div>
      </ContentSection>
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
